<template>
	<div :class="{'block-inner': true, 'has-file': fileUrls.length, 'align-right': block.meta.align === 'right', 'align-center': block.meta.align === 'center'}">
		<div v-if="fileUrls.length" class="current-file" :style="{ 'max-width': block.meta.maxWidth }">
			<pdf :src="fileUrls[0]" />
		</div>

		<div v-if="isFailed">
			<h2>Upload failed.</h2>
			<pre>{{ uploadError }}</pre>
		</div>

		<DropZone v-else :is-saving="isSaving" :accept-types="acceptTypes" @upload="filesChange" />
	</div>
</template>

<script>
	import DropZone from '../DropZone';
	import editorUploadMixin from '../../mixins/editorUploadMixin';
	import pdf from 'vue-pdf';

	export default {
		components: {
			DropZone,
			pdf
		},
		mixins: [editorUploadMixin],
		props: {
			block: {
				type: Object,
				required: true
			}
		},
		data() {
			return {
				acceptTypes: [
					{
						label: 'PDF',
						code: 'pdf',
						mimeTypes: ['application/pdf']
					}
				],
				allowOverwrite: true
			};
		},
		mounted() {
			this.reset();
		}
	};
</script>

<style lang="scss" scoped>
	.dropzone::v-deep {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;

		.file-upload {
			opacity: 0.5;

			&:hover {
				opacity: 0.85;
				backdrop-filter: blur(3px);
			}
		}
	}

	.block-inner {
		display: flex;
		flex-flow: row nowrap;

		&.align-right {
			justify-content: flex-end;
		}

		&.align-center {
			justify-content: center;
		}
	}
</style>